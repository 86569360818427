<template>
    <div class="flex flex-wrap align-items-center justify-content-center p-fluid">
        <Dialog v-model:visible="displayPortalDialog" :modal="true" :style="{ width: '350px' }" :closable="false"
            :dismissableMask="false" :draggable="false" :resizable="false" :contentStyle="{
                overflow: 'visible', borderBottomLeftRadius: '6px',
                borderBottomRightRadius: '6px',
            }">
            <template #header>
                <span class="mx-auto p-dialog-title">請選擇您欲使用的服務</span>
            </template>
            <div class="flex flex-column gap-4">
                <Button v-on:click="onOnsiteShops" label="網路來店點餐 (直接點餐)" class="p-button-purpply p-button" />
                <Button v-on:click="onPickupRegister" label="網路自取點餐 (預約自取)" class="p-button-purpply p-button" />
                <Button v-on:click="onDeliveryRegister" label="網路外送點餐 (預約外送)" class="p-button-purpply p-button" />
                <Button v-on:click="onMemberSite" label="品牌會員系統" class="p-button-purpply p-button" />
                <Button v-on:click="redirectQueryBranch" label="外送門市及低消查詢" class="p-button-purpply p-button" />
            </div>
        </Dialog>
        <div class="col-12 md:col-5">
            <div class="card">
                <div class="grid formgrid">
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <div class="w-full flex justify-content-center">
                            <img :src="brandLogo" width="200" />
                        </div>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-phone" />
                            <InputText type="number" id="phone" v-model="phoneValue" />
                            <label for="phone">手機號碼</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <span class="p-input-icon-left p-float-label">
                            <i class="pi pi-lock" />
                            <InputText type="password" id="password" v-model="passwordValue" />
                            <label for="password">密碼</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <div class="w-full flex justify-content-end">
                            <Button
                                class="h-2rem w-12rem flex align-content-center justify-content-center p-button-purpply p-button-outlined"
                                v-on:click="toggleMail">輸入電子郵件信箱</Button>
                        </div>
                        <span v-if="showEmail" class="p-input-icon-left p-float-label mt-2">
                            <i class="pi pi-envelope" />
                            <InputText type="text" id="email" v-model="emailValue" />
                            <label for="email">電子郵件信箱</label>
                        </span>
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <Button v-on:click="doLogin" label="登入" class="p-button-purpply p-button mr-2" />
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <Button v-on:click="redirectRegister" label="註冊" class="p-button-purpply p-button mr-2" />
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0">
                        <Button v-on:click="redirectQueryBranch" label="外送門市及低消查詢"
                            class="p-button-outlined p-button-secondary p-button mr-2" />
                    </div>
                    <div class="col-12 mb-2 mt-4 lg:col-12 lg:mb-0 text-center">
                        <span class="cursor-pointer" v-on:click="redirectSetPassword()">忘記密碼</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { useRoute } from "vue-router";

export default {
    data() {
        return {
            phoneValue: "",
            passwordValue: "",
            emailValue: "",
            brand: "anh",
            brandName: "一沐日",
            showEmail: false,
            displayPortalDialog: true,
        };
    },
    computed: {
        brandLogo() {
            // Adjust the mapping according to your available brand logos
            if (this.brand === "anh") {
                return "/images/logo-anh.png";
            } else if (this.brand === "nanhai-sado") {
                return "/images/logo-nanhai-sado.png";
            } else if (this.brand === "test") {
                return "/images/logo-nanhai-sado.png";
            }
            return "/images/default-logo.png";
        },
    },
    mounted() { },
    beforeUnmount() { },
    created() {
        const route = useRoute();
        const brand = route.query.b;
        if (brand == "anh") {
            this.brand = "anh";
            this.brandName = "一沐日";
        }
        if (brand == "nanhai-sado") {
            this.brand = "nanhai-sado";
            this.brandName = "南海茶道";
        }
        if (brand == "test") {
            this.brand = "test";
            this.brandName = "一木日";
        }
    },
    methods: {
        toggleMail() {
            this.showEmail = !this.showEmail;
        },
        doLogin() {
            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_API_BASE_URL + "/doAuthToken", {
                method: "GET",
                withCredentials: true,
                params: {
                    phone: this.phoneValue,
                    password: this.passwordValue,
                    email: this.emailValue,
                    b: this.brand,
                    s: "",
                },
            })
                .then((response) => {
                    if (response.data["code"] == 200)
                        this.doRedirect(response.data["token"]);
                    else alert("帳號或密碼錯誤");
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        doRedirect(token) {
            // login to online order also
            axios.defaults.withCredentials = true;
            axios(process.env.VUE_APP_ONLINE_ORDERING_SITE_API_BASE_URL + "/brandAuth", {
                method: "GET",
                withCredentials: true,
                params: {
                    phone: this.phoneValue,
                    password: this.passwordValue,
                    b: this.brand,
                },
            })
                .then((response) => {
                    if (response.data["code"] == 200) {
                        localStorage.onlineOrderToken = response.data["token"];
                    }
                    // store brand name first
                    localStorage.brand = this.brand;
                    localStorage.brandName = this.brandName;
                    localStorage.token = token;
                    this.$router.push({
                        path: "/landing", // Entrance
                        name: "landing",
                    });
                })
                .catch((err) => {
                    console.log(err.response);
                });
        },
        redirectRegister() {
            // store brand name first
            localStorage.brand = this.brand;
            localStorage.brandName = this.brandName;
            this.$router.push({
                path: "/register", // Entrance
                name: "register",
            });
        },
        redirectSetPassword() {
            localStorage.brand = this.brand;
            localStorage.brandName = this.brandName;
            this.$router.push({
                path: "/set_password", // Entrance
                name: "set_password",
            });
        },
        redirectQueryBranch() {
            localStorage.brand = this.brand;
            localStorage.brandName = this.brandName;
            this.$router.push({
                path: "/query_branch", // Entrance
                name: "query_branch",
            });
        },
        openExternalPage(page) {
            console.log(page);
            window.open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdIjeJ1FO1JcFj6CTDc1D_6BzY1LOKrLPgyUip9eSrKPuXwWA/viewform",
                "_blank"
            );
        },
        onOnsiteShops() {
            window.location.href = "https://menu.noder.tw/brands/08ZmJ0lw1g/shops";
        },
        onPickupRegister() {
            window.location.href = "https://menu.noder.tw/brands/08ZmJ0lw1g/pickup-register";
        },
        onDeliveryRegister() {
            window.location.href = "https://menu.noder.tw/brands/08ZmJ0lw1g/delivery-register";
        },
        onMemberSite() {
            this.displayPortalDialog = false;
        },
    },
};
</script>

<style scoped lang="scss">
.btn {
    border-radius: 10px;
    border-bottom: 0px;
    display: inline-block;
    padding: 10px;
    position: relative;
    text-align: center;
    transition: background 600ms ease, color 600ms ease;
}

input[type="radio"].toggle {
    display: none;

    &+label {
        cursor: pointer;
        min-width: 120px;
        background-color: var(--purpply-unselected-color);

        &:hover {
            background: none;
            color: var(--purpply-primary-color);
        }

        &:after {
            background-color: var(--purpply-primary-color);
            content: "";
            height: 100%;
            position: absolute;
            top: 0;
            transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
            width: 100%;
            z-index: -1;
        }
    }

    &.toggle-left+label {
        border-right: 0;

        &:after {
            left: 100%;
        }
    }

    &.toggle-right+label {
        margin-left: 0px;

        &:after {
            left: -100%;
        }
    }

    &:checked+label {
        cursor: default;
        color: #fff;
        background-color: var(--purpply-primary-color);
        transition: color 200ms;

        &:after {
            left: 0;
        }
    }
}
</style>
